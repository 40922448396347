<template>
    <v-snackbar
      v-model="snack"
      multi-line left
      rounded="pill"
      timeout="-1"
      @click.native="dialog = true"
    >
      <div>
        <v-icon class="mr-2">fas fa-alarm-clock</v-icon> {{ reservationInfo.count }} Active Reservation{{ reservationInfo.count > 1 ? 's'  : '' }}
      </div>
      <template v-slot:action>
        <div class="text-right" v-if="nextExpiry" :key="nextExpiry">
          <Countdown :expiry="nextExpiry" :lengthInSeconds="0" :mini="true" @expired="init"></Countdown>
        </div>
      </template>
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Active reservations</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="color1Text" @click.stop="dialog=false" icon>
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <loading-bar :value="loading.length > 0"></loading-bar>
          <v-card-text class="pt-3">
            <ReservationSpyCard
              v-for="(r, i) in reservationInfo.inProcess"
              :key="`${r.id}-${r.expiry}`"
              :reservation="r"
              :info="getInfo(r)"
              @complete-click="dialog=false"
              @expired="init"
              :i="i"
              :inCart="false"
            >
            </ReservationSpyCard>
            <ReservationSpyCard
              v-for="(r, i) in reservationInfo.inCart"
              :key="`${r.id}-${r.expiry}`"
              :reservation="r"
              :info="getInfo(r)"
              @complete-click="dialog=false"
              @expired="init"
              :i="i"
              :inCart="true"
            >
            </ReservationSpyCard>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'
const Countdown = () => import('@/components/Utils/Countdown')
const ReservationSpyCard = () => import('@/New/ReservationSpyCard')

export default {
  data () {
    return {
      snack: false,
      dialog: false,
      loading: [],
      info: []
    }
  },
  computed: {
    ...mapGetters(['reservationInfo']),
    nextExpiry () {
      return this.reservationInfo.all.length > 0 ? this.reservationInfo.all[0].expiry : null
    }
  },
  methods: {
    getInfo (r) {
      const i = this.info.find(f => f.id === r.id)
      return i
    },
    init () {
      this.$store.dispatch('CLEAN_RESERVATIONS')
        .then(() => {
          this.snack = !!this.reservationInfo.count
        })
      this.loadInfo()
    },
    keepAlive (reservation) {
      this.loading.push(`keep-alive-${reservation.id}`)
      this.$store.dispatch('KEEP_ALIVE', reservation)
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = this.loading.filter(f => f !== `keep-alive-${reservation.id}`)
        })
    },
    loadInfo () {
      const needInfo = this.reservationInfo.all.filter(r => {
        const x = this.info.findIndex(i => i.id === r.id)
        return x === -1
      }).map(m => m.id)

      if (needInfo.length) {
        this.loading.push('loadInfo')

        this.$VBL.http.post('tournament/reservation/info', { ids: needInfo })
          .then(r => {
            this.info.push(...r.data)
          })
          .catch(e => console.log(e.repsonse))
          .finally(() => {
            this.loading = this.loading.filter(f => f !== 'loadInfo')
          })
      }
    }
  },
  watch: {
    'reservationInfo.count': 'init'
  },
  mounted () {
    this.init()
  },
  components: {
    Countdown,
    ReservationSpyCard
  }
}
</script>
